import React, { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ButtonSubmit from "./ButtonSubmit";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { PathName } from "routers/types";
import Select from "shared/Select/Select";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { addLetterSofPlural } from "utils/string";

export const DEFAULT_ROOM_DATA = {
  id: Math.random(),
  guestAdults: 2,
  guestChildren: [],
};

export interface IRoom {
  guestAdults: number;
  guestChildren: number[];
  id?: number;
}

export interface GuestsInputProps {
  defaultValue: GuestsObject;
  rooms?: IRoom[];
  onChange?: (data: IRoom[] | any) => void;
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName | string;
  hasButtonSubmit?: boolean;
  defaultFocus?: boolean;
}

const GuestsInput: FC<GuestsInputProps> = ({
  rooms,
  defaultValue,
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/hotels",
  hasButtonSubmit = true,
}) => {
  const [data, setData] = useState<IRoom[]>(rooms || [DEFAULT_ROOM_DATA]);

  const setDataAndNotify = (newData: IRoom[]) => {
    setData(newData);
    onChange && onChange(newData);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const rooms: IRoom[] = [];

    for (let i = 1; i <= parseInt(params.get("rooms") ?? "0"); i++) {
      const guestAdults = parseInt(params.get(`adults${i}`) ?? "0");
      const guestChildren = (params.get(`children${i}`) ?? "")
        .split(",")
        .map((age) => parseInt(age));

      rooms.push({
        guestAdults,
        guestChildren: guestChildren.filter(Number.isFinite),
      });
    }

    if (rooms.length === 0) {
      setDataAndNotify([DEFAULT_ROOM_DATA]);
    } else {
      setDataAndNotify(rooms);
    }
  }, []);

  const handleChangeData = (
    value: number,
    type: keyof GuestsObject,
    roomIndex: number,
    childIndex?: number
  ) => {
    setDataAndNotify(
      data.map((room, index) => {
        if (roomIndex === index) {
          if (type === "guestAdults") return { ...room, guestAdults: value };
          if (type === "guestChildrenAge")
            return {
              ...room,
              guestChildren: room.guestChildren.map((_, i) =>
                i === childIndex ? value : _
              ),
            };
          return {
            ...room,
            guestChildren:
              value > room.guestChildren.length
                ? room.guestChildren.concat(0)
                : room.guestChildren.filter(
                    (_, i) => i + 1 !== room.guestChildren.length
                  ),
          };
        }
        return room;
      })
    );
  };

  const totalGuests = data.reduce(
    (acc, val) => {
      acc.total = acc.total + val.guestAdults + val.guestChildren.length;
      acc.totalAdults = acc.totalAdults + val.guestAdults;
      acc.totalChildren = acc.totalChildren + val.guestChildren.length;
      return acc;
    },
    {
      total: 0,
      totalAdults: 0,
      totalChildren: 0,
    }
  );

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} space-x-2 `}
              onClick={() => document?.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                  />
                </svg>
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold ">
                  {data.length} Chambre{addLetterSofPlural(data.length)}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {totalGuests.totalAdults
                    ? `${totalGuests.totalAdults} Adulte${addLetterSofPlural(
                        totalGuests.totalAdults
                      )}`
                    : ""}{" "}
                  {totalGuests.totalChildren
                    ? `, ${
                        totalGuests.totalChildren
                      } Enfant${addLetterSofPlural(totalGuests.totalChildren)}`
                    : ""}
                </span>
              </div>
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref as PathName} />
              </div>
            )}
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute w-max right-0 z-10 sm:min-w-[340px] bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              <div className="flex ">
                {data.map((room, index) => (
                  <div
                    key={room.id}
                    className={`max-h-80 overflow-y-auto ${
                      data.length !== 1 && data.length !== index + 1
                        ? "mr-5 pr-5 border-r border-solid"
                        : ""
                    }`}
                  >
                    <div className="flex items-center mb-2  pb-2">
                      <div className="mr-3 text-sm">Chambre {index + 1} </div>
                      {data.length !== 1 && (
                        <button
                          type="button"
                          onClick={() =>
                            setDataAndNotify(data.filter((_, i) => i !== index))
                          }
                        >
                          <MinusCircleIcon className="w-7 h-7" />
                        </button>
                      )}
                    </div>
                    <NcInputNumber
                      className="w-full mb-6"
                      defaultValue={room.guestAdults}
                      onChange={(value) =>
                        handleChangeData(value, "guestAdults", index)
                      }
                      max={10}
                      min={1}
                      label="Adultes"
                      desc=""
                    />
                    <NcInputNumber
                      className="w-full mb-6"
                      defaultValue={room.guestChildren.length}
                      onChange={(value) =>
                        handleChangeData(value, "guestChildren", index)
                      }
                      label="Enfants"
                      desc="Âges 0–17"
                    />
                    {room.guestChildren.map((child, i) => (
                      <div className="py-2" key={i}>
                        <div className="">Age Enfant {i + 1}</div>
                        <Select
                          onChange={(e) =>
                            handleChangeData(
                              Number(e.target.value),
                              "guestChildrenAge",
                              index,
                              i
                            )
                          }
                          value={child}
                        >
                          {Array.from({ length: 18 }, () => {}).map(
                            (_, age) => (
                              <option value={age} key={age}>
                                {age || "0-1"} an{addLetterSofPlural(age)}{" "}
                              </option>
                            )
                          )}
                        </Select>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              {data.length !== 4 && (
                <div className="flex justify-end mt-3 pt-3">
                  <button
                    type="button"
                    className="flex items-center"
                    onClick={() =>
                      setData(data.concat({ ...DEFAULT_ROOM_DATA }))
                    }
                  >
                    <PlusCircleIcon className="w-6 h-6 mr-2" />
                    <div className="text-sm">Ajouter une Chambre</div>
                  </button>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
