import React from "react";
import logo from "images/logos/Primary Logo.png";

const Footer: React.FC<{ hotelName?: string }> = ({ hotelName }) => {
  return (
    <div
      className={`w-full pb-20 lg:pb-0 py-3 text-center flex items-center justify-center`}
    >
      <b className="hidden lg:block">{hotelName} - Site officiel</b>{" "}
      <span className="hidden lg:block">
        <i className="las la-lock mx-1"></i> Réservation en ligne sécurisée
        propulsée par{" "}
      </span>
      <span className="lg:hidden block">
        <i className="las la-lock mx-1"></i> Powered by{" "}
      </span>
      <div
        className={`ttnc-logo ml-1 inline-block text-primary-blue focus:outline-none focus:ring-0 `}
      >
        <a href="https://kinkobooking.com/">
          <img className={`block max-h-4`} src={logo} alt="Logo" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
