import { FC, useState } from "react";

const NUMBER_OF_WORDS = 50;

const SeeMoreText: FC<{
  text: string;
  numberOfWords?: number;
  handleSeeMore?: () => void;
}> = ({
  text,
  numberOfWords = NUMBER_OF_WORDS,
  handleSeeMore,
}): JSX.Element => {
  const [showAllText, setShowAllText] = useState(false);

  const onClick = () => setShowAllText(!showAllText);

  return (
    <>
      {text
        .split(" ")
        .slice(0, (!showAllText && numberOfWords !== -1) ? numberOfWords : undefined)
        .join(" ")}
      {(text.split(" ").length > numberOfWords && numberOfWords !== -1) ? (
        <>
          {numberOfWords ? <span>...</span> : null}
          <div
            className="text-xs cursor-pointer underline text-black mt-3"
            onClick={handleSeeMore || onClick}
          >
            {showAllText ? "< Reduire" : "En savoir plus >"}
          </div>
        </>
      ) : null}
    </>
  );
};
export default SeeMoreText;
