import Page404 from "containers/Page404/Page404";
import { useSearch } from "context/SearchContext";
import Footer from "shared/Footer/Footer";
import BecomeAnAuthorImg from "images/BecomeAnAuthorImg.png";
import BasicHeader from "components/BasicHeader";

const CheckoutCancel = () => {
  const { myHotelInformation } = useSearch();

  return (
    <div className=''>
      <BasicHeader />
      <Page404 image={BecomeAnAuthorImg} message='Désolé nous n’avons pas pu traiter votre requête, prière de faire une nouvelle recherche' />
      <Footer hotelName={myHotelInformation?.name} />
    </div>
  );
};

export default CheckoutCancel;
