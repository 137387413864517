import React, { MouseEventHandler, useEffect, useState } from "react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { PathName } from "routers/types";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import { DEFAULT_ROOM_DATA, IRoom } from "components/HeroSearchForm/GuestsInput";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import Select from "shared/Select/Select";
import { addLetterSofPlural } from "utils/string";

export interface GuestsInputProps {
  defaultValue: GuestsObject;
  rooms?: IRoom[];
  onChange?: (data: IRoom[] | any) => void;
  className?: string;
  fieldClassName?: string;
  autoFocus?: boolean;
  buttonSubmitHref?: string;
  onClick: MouseEventHandler<HTMLDivElement>,
  onClickButton: Function
}

const GuestsInput: FC<GuestsInputProps> = ({
  rooms,
  defaultValue,
  onChange,
  fieldClassName = "[ nc-hero-field-padding--small ]",
  className = "",
  autoFocus = false,
  buttonSubmitHref = "/hotels",
  onClickButton,
  onClick
}) => {
  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(autoFocus);
  }, [autoFocus]);

  const data = rooms || [];
  const setData = (data: IRoom[]) => {
    onChange && onChange(data);
  };

  const handleChangeData = (
    value: number,
    type: keyof GuestsObject,
    roomIndex: number,
    childIndex?: number
  ) => {
    setData(
      data.map((room, index) => {
        if (roomIndex === index) {
          if (type === "guestAdults") return { ...room, guestAdults: value };
          if (type === "guestChildrenAge")
            return {
              ...room,
              guestChildren: room.guestChildren.map((_, i) => (i === childIndex ? value : _)),
            };
          return {
            ...room,
            guestChildren:
              value > room.guestChildren.length
                ? room.guestChildren.concat(0)
                : room.guestChildren.filter((_, i) => i + 1 !== room.guestChildren.length),
          };
        }
        return room;
      })
    );
  };

  const totalGuests = data.reduce(
    (acc, val) => {
      acc.total = acc.total + val.guestAdults + val.guestChildren.length;
      acc.totalAdults = acc.totalAdults + val.guestAdults;
      acc.totalChildren = acc.totalChildren + val.guestChildren.length;
      return acc;
    },
    {
      total: 0,
      totalAdults: 0,
      totalChildren: 0,
    }
  );
  return (
    <div className={`flex relative ${className}`} ref={refContainer} onClick={onClick}>
      <div
        className={`flex flex-1 text-left justify-between items-center focus:outline-none cursor-pointer ${isOpen ? "nc-hero-field-focused--2" : ""
          }`}>
        <div
          className={`${fieldClassName} flex-1`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <div className='flex-1 text-left'>
            <span className='block font-semibold'>
              {data.length} Chambre{addLetterSofPlural(data.length)}
            </span>
            <span className='block mt-1 text-sm text-neutral-400 leading-none font-light'>
              {totalGuests.totalAdults
                ? `${totalGuests.totalAdults} adulte${addLetterSofPlural(totalGuests.totalAdults)}`
                : ""}{" "}
              {totalGuests.totalChildren
                ? `, ${totalGuests.totalChildren} enfant${addLetterSofPlural(
                  totalGuests.totalChildren
                )}`
                : ""}
            </span>
          </div>
        </div>
        <div className='pr-2'>
          <ButtonSubmit onClick={onClickButton} />
        </div>
      </div>
      {isOpen && (
        <div className='absolute w-max right-0 z-10 sm:min-w-[340px] bg-white dark:bg-primary-gray top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl'>
          <div className='flex '>
            {data.map((room, index) => (
              <div
                key={index}
                className={`${data.length !== 1 && data.length !== index + 1
                  ? "mr-5 pr-5 border-r border-solid"
                  : ""
                  }`}>
                <div className='flex items-center mb-2  pb-2'>
                  <div className='mr-3'>Chambre {index + 1} </div>
                  {data.length !== 1 && (
                    <button
                      type='button'
                      onClick={() => setData(data.filter((_, i) => i !== index))}>
                      <MinusCircleIcon className='w-7 h-7' />
                    </button>
                  )}
                </div>
                <NcInputNumber
                  className='w-full mb-6'
                  defaultValue={room.guestAdults}
                  onChange={value => handleChangeData(value, "guestAdults", index)}
                  max={10}
                  min={1}
                  label='Adultes'
                  desc=''
                />
                <NcInputNumber
                  className='w-full mb-6'
                  defaultValue={room.guestChildren.length}
                  onChange={value => handleChangeData(value, "guestChildren", index)}
                  label='Enfants'
                  desc='Ages 0–17'
                />
                {room.guestChildren.map((child, i) => (
                  <div className='py-2' key={i}>
                    <div className=''>Age Enfant {i + 1}</div>
                    <Select
                      onChange={e =>
                        handleChangeData(Number(e.target.value), "guestChildrenAge", index, i)
                      }
                      value={child}>
                      {Array.from({ length: 18 }, () => { }).map((_, age) => (
                        <option value={age} key={age}>
                          {age || "0-1"} an{addLetterSofPlural(age)}
                        </option>
                      ))}
                    </Select>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {data.length !== 4 && (
            <div className='flex justify-end mt-3 pt-3'>
              <button
                type='button'
                className='flex items-center'
                onClick={() => setData(data.concat({ ...DEFAULT_ROOM_DATA }))}>
                <PlusCircleIcon className='w-6 h-6 mr-2' />
                <div className=''>Ajouter une Chambre</div>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GuestsInput;
