import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import ModalBookingDetails from "components/ModalBookingDetails";
import { ISelectedArrangements } from "components/StayCardPricesTable/StayCardPricesTable";
import { ISearchResult } from "interfaces/searchResult";
import { useSearch } from "context/SearchContext";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { calculateStayTotalPrice } from "utils/prices.utils";
import ModalReserveMobile from "./ModalReserveMobile";

const MobileFooterSticky: React.FC<{
  hotel?: ISearchResult;
  selectedArrangements?: ISelectedArrangements;
  selectedRooms?: {
    [roomIndex: number]: number /* number = roomTypeId */;
  };
  selectedDate: DateRage;
  handleConfirmButton: () => void;
  loading: boolean;
}> = ({
  hotel,
  selectedArrangements,
  selectedRooms,
  selectedDate,
  handleConfirmButton,
  loading,
}) => {
    const { userType, searchData } = useSearch();

    const calculateTotalPrice = () => {
      return calculateStayTotalPrice(
        userType!,
        hotel?.occupancies!,
        selectedRooms!,
          selectedArrangements!
      );
    };

    return (
      <div className='block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-primary-gray border-t border-neutral-200 dark:border-neutral-6000 z-20'>
        <div className='container flex items-center justify-between'>
          <ModalBookingDetails
            hotel={hotel}
            selectedArrangements={selectedArrangements}
            selectedRooms={selectedRooms}
            loading={loading}
            handleConfirmButton={handleConfirmButton}
            totalPrice={calculateTotalPrice().price}
            totalPriceWithoutPromotion={calculateTotalPrice().price_without_promotion}
            renderChildren={({ openModal }) => (
              <div className='w-full'>
                <span className='block text-xl font-semibold'>{calculateTotalPrice().price} {searchData?.currency}</span>
                <span onClick={openModal} className='block text-sm underline font-medium capitalize'>
                  {converSelectedDateToString(selectedDate)}
                </span>
              </div>
            )}
          />
          <ModalReserveMobile
            renderChildren={() => (
              <ButtonPrimary
                loading={loading}
                onClick={handleConfirmButton}
                sizeClass='px-5 sm:px-7 py-3 !bg-green-700 !rounded-2xl'>
                Réserver
              </ButtonPrimary>
            )}
          />
        </div>
      </div>
    );
  };

export default MobileFooterSticky;
