import { FC } from "react";

const IconAndText: FC<{name: string, icon: string}> = ({ name, icon }) => {
  return (
    <div key={name} className="flex items-center space-x-3">
      <i className={`text-xl las ${icon}`}></i>
      <span className="text-sm ">{name}</span>
    </div>
  );
};

export default IconAndText;
