import { ISelectedArrangements } from "components/StayCardPricesTable/StayCardPricesTable";
import { ISearchResult } from "interfaces/searchResult";
import moment from "moment";
import { FC } from "react";
import { addLetterSofPlural } from "utils/string";

export const DisplaySideBarBody: FC<{
  hotel: ISearchResult;
  selectedArrangements: ISelectedArrangements;
  selectedRooms: {
    [roomIndex: number]: number /* number = roomTypeId */;
  };
}> = ({ hotel, selectedArrangements, selectedRooms }) => {
  return (
    <>
      {hotel?.occupancies?.map((room, index) => {
        const roomType = room.room_types.find(
          (type) => type.data.id === selectedRooms?.[index]
        );
        const boardId =
          roomType &&
          selectedArrangements[index] &&
          selectedArrangements[index][roomType.data.id] &&
          selectedArrangements[index][roomType.data.id].boardId;

        return (
          <div className="" key={index}>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>
            <div className="font-bold mb-2 ">Chambre {index + 1}</div>
            <div className="text-sm">
              {room.pax.adult} Adulte{addLetterSofPlural(room.pax.adult)}{" "}
              {room.pax.child_ages.length
                ? `, ${room.pax.child_ages.length} Enfant${addLetterSofPlural(
                    room.pax.child
                  )} ( ${room.pax.child_ages.map(
                    (el) =>
                      " " + (el || "0-1") + " an" + addLetterSofPlural(el) + " "
                  )})`
                : ""}
            </div>
            <div className="text-sm">
              {roomType?.data.name} -{" "}
              {
                room.room_types
                  .find((type) => type.data.id === selectedRooms?.[index])
                  ?.board.find((arr, index) =>
                    boardId ? arr.data.id === boardId : index === 0
                  )?.data.name
              }
            </div>
          </div>
        );
      })}

      {hotel?.cancellation_deadline?.is_free_cancellation && (
        <>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 "></div>
          <div className="">
            <div className="font-bold mb-2">Conditions d'annulation</div>
            <div className="text-sm text-green-600 mb-2">
              Annulation gratuite{" "}
              {hotel.cancellation_deadline?.until &&
                `jusqu'au
                  ${moment(
                    hotel.cancellation_deadline?.until,
                    "DD-MM-YYYY"
                  ).format("DD-MM-YYYY")}`}
            </div>
          </div>
        </>
      )}
    </>
  );
};
